/* eslint-disable no-useless-escape */
import Constants from "../constants";
import { getAPIService, postAPIService } from "../components/API";
import { Buffer } from "buffer";
import Axios from "axios";

const axios = Axios.create();

export const getProductsPayload = ({
  categoryId = "",
  selectedFilters: applyFilters = {},
  searchType = "",
  curPage = "",
}) => {
  let selectedFacets = {};

  const filterKeys = Object.keys(applyFilters);

  if (filterKeys?.length) {
    selectedFacets = { ...selectedFacets, ...applyFilters };
  }
  let offset = curPage > 0 ? parseInt(curPage - 1) : curPage;

  const payload = {
    searchValue: categoryId,
    searchType: searchType || "category",
    selectedSort: "sortBy_priceHighToLow",
    pageSize: 10,
    offset,
    selectedFacets,
  };
  return { ...payload };
};

export const getAuthCredentails = async ({
  phone = "",
  emailID = "",
  type = "",
}) => {
  const URL = Constants.Auth.getToken;
  const payload = {
    otpType: "EMAIL",
    email: emailID,
    type: type,
  };

  const response = await postAPIService({
    url: URL,
    data: payload,
  })
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const verifyAuthCredentails = async ({
  phone = "",
  emailID = "",
  type = "",
  userOTP = "",
  isMergeCart = false,
}) => {
  const { userId: guestId = "" } = decodeGuestToken();
  const URL = Constants.Auth.verifyToken;
  const payload = {
    otpType: "EMAIL",
    email: emailID,
    userOTP: userOTP,
    type: type,
  };

  let headers = {
    "Content-Type": "application/json",
  };

  if (isMergeCart) {
    headers = {
      ...headers,
      guestCartId: guestId,
    };
  }
  const response = await postAPIService({
    url: URL,
    data: payload,
    headers: { ...headers },
  })
    .then((response) => response)
    .catch((error) => error);

  return response;
};

export const getGuestToken = async () => {
  const URL = Constants.Auth.guestToken;
  const response = await getAPIService({ url: URL })
    .then((response) => response)
    .catch((error) => error);
  return response;
};

export const decodeGuestToken = () => {
  let token = localStorage.getItem("guestToken");
  if (token !== null) {
    const {
      isGuestUser = "",
      token: Token = "",
      userId = "",
    } = JSON.parse(token);
    return { isGuestUser, Token, userId };
  }
  return false;
};

export const decodeUserToken = () => {
  let token = localStorage.getItem("userToken");
  if (token !== null) {
    const {
      isAuthenticated = "",
      token: Token = "",
      userId = "",
      contactDetails = [],
    } = JSON.parse(token);
    return { isAuthenticated, Token, userId, contactDetails };
  }
  return false;
};

export const chooseToken = () => {
  if (!decodeUserToken()) {
    return decodeGuestToken();
  } else {
    return decodeUserToken();
  }
};

export const ItemATCPayload = ({ selectedProduct, type, count = 1 }) => {
  const { selectedSize = "" } = selectedProduct || {};
  const { sku = "" } = selectedProduct[selectedSize] || {};
  let variantTypeString = sku?.length > 0 && sku.split("_");
  let variantSKU = variantTypeString?.[0] || "";
  let variantName = variantTypeString?.slice(1).join("_") || "";
  const { userId = "" } = chooseToken();
  const payload = {
    cartId: userId,
    type: type,
  };
  payload.cartItem = {
    sku: variantSKU,
    variantType: variantName || null,
    count: count,
  };
  return { ...payload };
};

export const getNoVariantPayload = ({ productDetails, type, count = 1 }) => {
  const { sku = "", variantType = "" } = productDetails || {};
  const { userId = "" } = chooseToken();
  const payload = {
    cartId: userId,
    type: type,
  };
  payload.cartItem = {
    sku,
    variantType: variantType,
    count: count,
  };
  return { ...payload };
};

export const buyNowPayload = ({
  selectedProduct,
  pincode = "560016",
  count = 1,
}) => {
  const { selectedSize = "" } = selectedProduct || {};
  const { sku = "" } = selectedProduct[selectedSize] || {};
  let variantTypeString = sku?.length > 0 && sku.split("_");
  let variantSKU = variantTypeString?.[0] || "";
  let variantName = variantTypeString?.slice(1).join("_") || "";
  const { userId = "" } = chooseToken();
  const cartItemData = {
    sku: variantSKU,
    variantType: variantName,
    count,
  };

  const payload = {
    pincode,
    cartId: userId,
    cartItem: { ...cartItemData },
  };

  return payload;
};

export const getNoVariantBuyNowPayload = ({
  productDetails,
  pincode = "560016",
  count = 1,
}) => {
  const { sku = "", variantType = "" } = productDetails || {};
  const { userId = "" } = chooseToken();
  const cartItemData = {
    sku,
    variantType: variantType || "",
    count,
  };

  const payload = {
    pincode,
    cartId: userId,
    cartItem: { ...cartItemData },
  };

  return {
    ...payload,
  };
};

export const getCartPriceDetails = (allcartItems) => {
  const amount = allcartItems?.reduce((prev, curr, index) => {
    let obj = {};
    obj.itemsLen = prev?.itemsLen ? prev?.itemsLen + index : index + 1;
    obj.totPrice = prev?.totPrice
      ? prev?.totPrice + curr?.price?.price
      : curr?.price?.price;
    obj.deliveryCharge = 250;
    obj.discountAmount = prev?.discountAmount
      ? prev?.discountAmount + curr?.price?.discountedPrice
      : curr?.price?.discountedPrice;
    obj.packingCharge = 99;
    return { ...prev, ...obj };
  }, {});
  return amount;
};

export const productMap = (data) => {
  const payload = { ...data };
  const { productVariantMap = {} } = data;
  let productMapKeys = Object.keys(productVariantMap);
  let productMapping = {};
  productMapKeys.forEach((item) => {
    let obj = productVariantMap[item];
    let splitVal = item.split("_");
    let sizeVal = splitVal[1];
    let colorVal = splitVal.slice(2).join("-");
    if (productMapping[colorVal]) {
      productMapping[colorVal][sizeVal] = obj;
    } else {
      let obj1 = {};
      obj1[sizeVal] = obj;
      obj1["imgUrl"] = obj.imageUrls[0];
      productMapping[colorVal] = obj1;
    }
  });
  payload.variantDetailsMap = productMapping;
  return payload;
};

export const UpdateCartItemsPayload = (props) => {
  const {
    skuID = "",
    variantType = "",
    newValue = 0,
    action = "updateItem",
  } = props;

  const type = "UPDATE_COUNT";
  const { userId = "" } = chooseToken();
  const payload = {
    cartId: userId,
    type: type,
  };
  payload.cartItem = {
    sku: skuID,
    variantType: variantType || null,
    count: action === "updateItem" ? newValue : 0,
  };
  return { ...payload };
};

export const getPaymentAuthHeader = () => {
  const options = {
    Username: "rzp_test_mS7B6c2rLO1bGI",
    Password: "zMRZGAe4L8wEV4zZvUc3OHUO",
  };
  const base64EcodedData = Buffer.from(
    options?.Username + ":" + options?.Password
  ).toString("base64");

  return base64EcodedData;
};

export const getDate = (data) => {
  const Newdate = new Date(data);
  const date = Newdate?.toDateString();
  return date;
};

export const getDeliveryRate = async ({
  sku,
  variantType = "",
  pinCode = "",
}) => {
  const { Token = "" } = chooseToken();
  const URL = Constants.cart.getRate;
  const headers = {
    Authorization: `Bearer ${Token}`,
  };
  const ratePayload = {
    destinationPincode: pinCode,
    itemInfos: [
      {
        productSku: {
          sku,
          variantType,
        },
      },
    ],
  };

  return await axios
    .post(
      URL,
      {
        ...ratePayload,
      },
      {
        headers: {
          ...headers,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const mobileNumValidation = (inputtxt) => {
  var regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (inputtxt.match(regex)) {
    return true;
  } else {
    return false;
  }
};

export const pinCodeValidation = (inputtext) => {
  const regx = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
  if (regx.test(inputtext)) {
    return true;
  } else {
    return false;
  }
};

export const EmailIdValidation = (inputText) => {
  const regx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (inputText.match(regx)) {
    return true;
  } else {
    return false;
  }
};
